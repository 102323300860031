import Signal from 'signal-js';
import cookies from 'cookies-js';
import client from './utils/client';
import Store from '@immutabl3/store';
import queryString from 'query-string';
import localStore from './utils/localStore';
import { window } from '@immutabl3/alchemy';
import { isWindows } from '@immutabl3/browser-environment';
import {
  load,
  preload,
  loadUser,
  loadInsights,
} from './load';
import {
  once,
  pick,
} from '@immutabl3/utils';
import {
  isDev,
  isClient,
  UTM_KEYS,
  ACCEPTANCE_ID,
} from '@artica/constants'; 

if (isClient) {
  window.options({
    // set touch scroll sensitivity
    touchYSpeed: 3,

    // reduce scroll wheel sensitivity
    mouseWheelYSpeed: 0.9,

    // give windows inertia scroll
    inertiaScrollEnabled: isWindows(),
  });
}

// kickoff preload as soon as possible
const preloading = isClient ? preload() : Promise.resolve();

const SCENE_READY_DELAY = 500;

export default function App(props = {}) {
  const signal = Signal();
  const store = Store({
    ...props,
    user: {
      id: '',
      accepted: false,
      email: '',
      emailPopupClosed: false,
      category: '',
      categories: {},
      insight: {
        qa: [],
        insights: [],
      },
      personalization: {
        companyName: '',
        companyLogoUrl: '',
        companySize: 'entrepreneur',
      },
    },
    menuActive: false,
    preloaded: false,
    sceneloaded: false,
    loaded: false,
    references: {},
    insights: [],
  });

  // persist the acceptance cookie
  const updateAcceptanceCookie = () => {
    const accepted = store.get(['user', 'accepted']);
    cookies.set(ACCEPTANCE_ID, accepted ? 'true' : '');
  };

  const kickOffAssetLoad = async function() {
    const loading = load();

    await preloading;
    store.set(['preloaded'], true);

    await loading;
    store.set(['loaded'], true);
  };

  const init = once(async function() {
    const loading = kickOffAssetLoad();

    const userData = await loadUser(store);
    store.merge(['user'], userData);
    
    // if the user has submitted the form, disable the popup
    if (store.get(['user', 'email'])) {
      store.set(['user', 'emailPopupClosed'], true);
    }

    // if the user has already accepted, set the cookie
    // this will also clear the cookie if the user has not accepted
    updateAcceptanceCookie();
    
    // kickoff insight load, but don't wait for it
    loadInsights(store);

    await loading;

    isDev && console.log(globalThis.store = store);
  });

  // persist the user to session
  store.watch(['user'], () => {
    const user = store.get(['user']);
    localStore.set(user.id, user);
  });

  // when accepted, saturate the personalization
  store.watch(['user', 'accepted'], async function() {
    // already accepted, don't do anything
    if (!store.get(['user', 'accepted'])) return;

    // accepted, set the cookie!
    store.set(['user', 'accepted'], true);
    updateAcceptanceCookie();

    // already has personalization, don't do anything
    if (store.get(['user', 'personalization', 'companyName'])) return;

    // saturate the personalization
    const {
      companyName = '',
      companyLogoUrl = '',
      companySize = 'entrepreneur',
    } = await client.query('user');
    store.set(['user', 'personalization'], {
      companyName,
      companyLogoUrl,
      companySize,
    });
  });

  // persist the insights request
  store.watch(['insights'], () => {
    localStore.set('insights', store.get(['insights']));
  });

  signal.on('user:accept', async function() {
    store.set(['user', 'accepted'], true);
  });

  signal.on('user:category:select', async function(category) {
    store.set(['user', 'category'], { ...category });

    const categoryId = category.id;
    const insight = store.get(['user', 'categories', categoryId])
      ? store.get(['user', 'categories', categoryId])
      : await client.query('insight', { categoryId });
    store.set(['user', 'categories', categoryId], insight);
    store.set(['user', 'insight'], insight);
  });
  
  signal.on('user:lead', async function(data) {
    store.set(['user', 'email'], data.email);
    
    const qs = queryString.parse(globalThis.location.search);
    const qsData = pick(qs, UTM_KEYS);
    await client.query('lead', {
      ...data,
      ...qsData,
    });
  });

  signal.on('user:email:dismiss', () => {
    store.set(['user', 'emailPopupClosed'], true);
  });
  
  signal.on('menu:toggle', active => {
    store.set(['menuActive'], active ?? !store.get(['menuActive']));
  });

  signal.on('references:add', (key, ref) => {
    if (!store.get(['references', key])) {
      store.set(['references', key], [ref]);
      return;
    }

    if (store.get(['references', key]).includes(ref)) return;

    store.push(['references', key], ref);
  });

  signal.on('references:remove', (key, ref) => {
    store.set(['references', key], 
      (store.get(['references', key]) ?? [])
        .filter(camera => camera !== ref)
    );
  });
  
  signal.on('scene:ready', () => {
    setTimeout(() => {
      store.set(['sceneloaded'], true);
    }, SCENE_READY_DELAY);
  });

  return {
    init,
    store,
    signal,
  };
};