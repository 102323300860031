import { useMemo, useEffect, useRef } from 'react';
import { anime, stagger } from '@immutabl3/anime';

const createAnimation = ref => anime({
  targets: ref.current.children,
  easing: 'easeInSine',
  translateX: [-10, 0],
  opacity: [0, 1],
  duration: 85,
  autoplay: false,
  delay: stagger(45, { start: 500 }),
});

export const useTypewriter = (str, active) => {
  const ref = useRef();
  const characters = useMemo(() => {
    return str
      .split('')
      .map((char, idx) => ({
        key: idx,
        children: char,
      }));
  }, [str]);

  useEffect(() => {
    if (!active) return void createAnimation(ref);

    const anim = createAnimation(ref);
    anim.play();

    return () => {
      anim.restart();
      anim.pause();
    };
  }, [active]);

  return {
    ref,
    characters,
  };
};