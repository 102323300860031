import style from './ContactPhone.style';
import Phone from '../Phone';
import SlideIn from './SlideIn';
import SectionTag from './SectionTag';

export default function ContactPhone({ phone, active }) {
  return (
    <div css={ style }>
      <SectionTag active={ active }>
        Contact
      </SectionTag>
      <SlideIn
        as="p"
        active={ active }
        index={ 1 }
      >
        <Phone tel={ phone } />
      </SlideIn>
    </div>
  );
};