import { css } from '@emotion/react';

export default css`
  position: fixed;
  z-index: var(--z-launchpad-mask);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: hsl(
    var(--color-artica-midnight-h)
    var(--color-artica-midnight-s)
    var(--color-artica-midnight-l)
    / 0
  );
  backdrop-filter: blur(0px);
  pointer-events: none;
  will-change: backdrop-color, backdrop-filter;
  translate: 0 0 1px;

  &[data-active="true"] {
    transition:
      background-color 300ms ease,
      backdrop-filter 300ms linear;
      
    backdrop-filter: blur(10px);
    background-color: hsl(
      var(--color-artica-midnight-h)
      var(--color-artica-midnight-s)
      var(--color-artica-midnight-l)
      / 0.7
    );
    // capture the pointer events
    // so that we're not hovering
    // over items in the background
    pointer-events: auto;
  }
`;