import style from './Input.style';

export default function Input({
  children,
  ...rest
}) {
  return (
    <label css={ style }>
      { children }
      <input { ...rest } />
      <div />
    </label>
  );
};