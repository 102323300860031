import Journey, { config } from '../journey';

import { Global } from '@emotion/react';
import Launchpad from '../components/Launchpad';
import Script from 'next/script';
import { global as theme } from '@artica/theme';
import { useAlchemy } from '@immutabl3/alchemy';
import { useEffect } from 'react';
import { useSignalContext } from '@artica/hooks';
import { useContext as useStoreContext } from '@immutabl3/store/react';

const app = Journey();

export default function App({ Component, pageProps }) {
  app.store.merge({ pageProps });
  // const app = useMemo(() => Journey({ pageProps }), []);

  const { store, signal } = app;
  const StoreContext = useStoreContext(store);
  const SignalContext = useSignalContext(signal);
  const AlchemyContext = useAlchemy(config);

  useEffect(() => void app.init(), []);

  return (
    <>
      <Script id='google-tag-manager' strategy='afterInteractive'>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TJ4JZSL');
          `}
      </Script>
      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=AW-11035699326"
      />
      <Script
        id="google-ads"
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-11035699326');
          `,
        }}
      />
      <Script
        id="meta-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                  n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '5220002908101597');
                fbq('track', 'PageView');
                `
        }}
      />
      <StoreContext>
        <SignalContext>
          <AlchemyContext>
            <Global styles={theme} />
            <Launchpad {...pageProps} />
            <Component
              {...pageProps}
              StoreContext={StoreContext}
              SignalContext={SignalContext}
              AlchemyContext={AlchemyContext}
            />
          </AlchemyContext>
        </SignalContext>
      </StoreContext>
    </>
  );
}
