import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: var(--z-launchpad);
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 29rem;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  background-color: var(--color-artica-dark-wave);
  transition: opacity 300ms ease;
  translate: -25% 0 1px;

  will-change: opacity, translate;

  form {
    flex-shrink: 0;
    margin: 0 -1rem 3.125rem -1rem;
    padding: 1.25rem 1rem;
    background-color: var(--color-artica-ocean-blue);
    
    p {
      padding: 0 1rem;
    }
    
    @media ${mq.md} {
      display: none;
    }
  }

  &[data-active="true"] {
    pointer-events: auto;
    opacity: 1;
    translate: 0 0 1px;

    transition:
      opacity 250ms ease,
      translate 250ms ease;
  }
`;