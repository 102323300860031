import { css } from '@emotion/react';
import {
  borderClipPath,
  overflowedGradientBg,
} from '@artica/theme';

export default css`
  display: block;
  isolation: isolate;
  position: relative;
 
  --border-width: 1px;

  input {
    position: relative;
    width: 100%;
    padding: 1.125rem 2.5rem 1.25rem;
    border: 0;
    color: var(--color-artica-snow);
    font-size: 1.25rem;
    font-family: var(--font-family);
    box-shadow: 0 0 1rem hsl(0deg 0% 100% / 0);
    background-color: transparent;
    transition: box-shadow 400ms ease;

    ::placeholder {
      color: rgb(255 255 255 / 0.35);
    }

    + div {
      position: absolute;
      inset: 0;
      ${overflowedGradientBg}
      ${borderClipPath};
    }
  }

  &:hover,
  &:focus-within {
    input {
      box-shadow: 0 0 0.75rem hsl(0deg 0% 100% / 0.25);
      + div {
        background-position: 50% 50%;
      }
    }
  }

  &:focus-within {
    input {
      box-shadow: 0 0 1.25rem hsl(0deg 0% 100% / 0.5);
    }
    + div {
      background-position: 100% 100%;
    }
  }
`;