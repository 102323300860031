import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  --line-color: var(--color-artica-snow);

  position: fixed;
  z-index: var(--z-hamburger);
  top: 0;
  left: 0;

  // approx 65px
  width: 4.0625rem;
  height: 4.0625rem;

  translate: 0 0 1px;
  
  &:before,
  &:after,
  span,
  span:before,
  span:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 25%;
    background-color: var(--line-color);
    scale: 1 1;
    translate: 0 0;
    transform-origin: 0 0;

    transition:
      opacity 200ms ease-in,
      translate 200ms ease,
      rotate 200ms ease-in,
      scale 200ms ease-in;
  }

  &:before {
    width: 25%;
    opacity: 0;
    translate: -1px 0;
    rotate: -20deg;
  }
  &:after {
    width: 25%;
    opacity: 0;
    translate: 0 -1px;
    rotate: 20deg;
  }

  span {
    top: 50%;
    left: 25%;
    translate: 0 -0.5px;
    scale: 0.355 1;

    &:before {
      bottom: 350%;
      left: 0;
      scale: 1.2 1;
    }
    &:after {
      top: 350%;
      left: 0;
      scale: 0.7 1;
    }
  }

  &[aria-expanded="true"] {
    &:before {
      opacity: 1;
      translate: 3px 0;
      rotate: -40deg;
    }
    &:after {
      opacity: 1;
      translate: 4px -1px;
      rotate: 40deg;
    }

    span {
      translate: 4px -0.5px;

      &:before {
        opacity: 0;
        translate: 0 -200%;
        scale: 0 1;
      }
      &:after {
        opacity: 0;
        translate: 0 200%;
        scale: 0 1;
      }
    }
  }

  @media ${mq.md} {
    top: 2rem;
    left: 2rem;
    border: 2px solid transparent;
    background-color: transparent;

    transition:
      border-color 300ms ease,
      background-color 300ms ease;

    &[aria-expanded="true"] {
      border-color: var(--color-artica-ocean-blue);
      background-color: var(--color-artica-dark-wave);
    }

    &:hover {
      --line-color: var(--color-artica-neo-green);

      span {
        &:before {
          translate: 0 -100%;
        }
        &:after {
          translate: 0 100%;
        }
      }
    }
  }

  &[data-overlaid="true"] {
    mix-blend-mode: exclusion;

    @media ${mq.md} {
      mix-blend-mode: unset;
    }
  }
`;