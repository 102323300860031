import { css } from '@emotion/react';

export default css`
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
  height: var(--link-height);
  margin-left: -1rem;
  padding: 0 0 0 1rem;
  color: var(--color-artica-snow);
  font-size: 1.5rem;
  text-decoration: none;
  transition: color 300ms ease;

  &:first-of-type {
    margin-top: 0.5rem;
  }

  &:hover {
    color: var(--color-artica-neo-green);
  }

  &[data-active="true"] {
    color: var(--color-artica-neo-green);
  }
`;