import { css } from '@emotion/react';

export default css`
  display: flex;
  gap: 1.5rem;
  margin: 0 0 3rem 1rem;

  a {
    display: block;
    width: 2rem;
    aspect-ratio: 1;
    fill: var(--color-artica-snow);
  }
`;