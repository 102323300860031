import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 0 3rem 1rem;
`;

export const list = css`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 1rem;

  @media ${mq.md} {
    flex-direction: row;
    gap: 3rem;
  }
`;