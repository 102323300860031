import { ARTICA_STORE_VERSION } from '@artica/constants';

const store = globalThis.sessionStorage;

const makeKey = (key :string) => `${key}::${ARTICA_STORE_VERSION}`;

const impl = {
  get(key: string): any {
    const result: string = store.getItem(makeKey(key)) ?? 'null';
    return JSON.parse(result) ?? undefined;
  },
  set(key: string, value: any): any {
    store.setItem(makeKey(key), JSON.stringify(value));
    return value;
  },
};

export default impl;