import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  flex-grow: 1;
  margin-top: 4.0625rem;
  padding: 2rem 1rem 2rem 1rem;
  overflow-y: scroll;

  @media ${mq.md} {
    display: flex;
    flex-direction: column;
    margin-top: 6.0625rem;
  }
`;