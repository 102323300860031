import { isDev } from '@artica/constants';
import { css } from '@emotion/react';
import { parseToHsl } from 'polished';

const COLORS = {
  '--color-artica-snow': '#fff',
  '--color-artica-cloud': '#dcdcdc',
  '--color-artica-ocean-blue': '#1211f5',
  '--color-artica-dark-wave': '#0e02b7',
  '--color-artica-neo-green': '#00ff24',
  '--color-artica-coal': '#282835',
  '--color-artica-midnight': '#000',
};

export const root = css`
  :root {
    ${Object.entries(COLORS)
    .map(([key, hex]) => [key, parseToHsl(hex)])
    .map(([key, { hue, saturation, lightness }]) => `
      ${key}-h: ${hue}deg;
      ${key}-s: ${saturation * 100}%;
      ${key}-l: ${lightness * 100}%;
      ${key}: hsl(var(${key}-h) var(${key}-s) var(${key}-l));
    `).join('\n')}

    --font-family: 'Lato', ${isDev ? 'monospace' : 'sans-serif'};
    --font-family-display: 'Söhne Breit', ${isDev ? 'monospace' : 'sans-serif'};

    --font-size-base: 1em;

    --font-size-h1: calc(var(--font-size-base) * 2);
    --font-size-h2: calc(var(--font-size-base) * 1.5);
    --font-size-h3: calc(var(--font-size-base) * 1.25);
    --font-size-h4: calc(var(--font-size-base) * 1.15);
    --font-size-h5: calc(var(--font-size-base) * 1);
    --font-size-h6: var(--font-size-base);

    --z-hamburger: 30;
    --z-main-header: 25;
    --z-launchpad: 20;
    --z-launchpad-mask: 15;
    --z-launchpad-mask: 15;
    --z-signup: 10;
    --z-mask: 5;
    --z-main: 0;

    --display-width: 16rem;
    --display-width-s: 17.8125rem; // arbitrary
    --display-width-m-flex: 18.75em; // arbitrary
    --display-width-l: 20rem; // x1.25
    --display-width-xl: 24rem; // x1.5
    --display-width-xxl: 28rem; // x1.75
  }
`;

export const mq = {
  lg: '(min-width: 1200px)',
  md: '(min-width: 992px)',
  sm: '(min-width: 768px)',
  xs: '(min-width: 480px)',
  mi: '(min-width: 320px)',
};

export const overflowedGradientBg = css`
  background-position: 0 0;
  background-size: 200% 200%;
  background-image: linear-gradient(
    to bottom right,
    rgb(255 255 255 / 0.73) 0%,
    rgb(255 255 255 / 0.15) 50%,
    rgb(255 255 255) 100%
  );
  transition: background-position 300ms ease;
`;

// see frame impl
// https://bennettfeely.com/clippy/
// NOTE: stored here for reuse cause css variables dont inherit
// in a way that makes this useful as a css variable
export const borderClipPath = css`
  clip-path: polygon(
    0% 0%,
    0% var(--frame-height, 100%),
    var(--frame-stroke, 1px) var(--frame-height, 100%),
    var(--frame-stroke, 1px) var(--frame-stroke, 1px),
    calc(var(--frame-width, var(--frame-height, 100%)) - var(--frame-stroke, 1px)) var(--frame-stroke, 1px),
    calc(var(--frame-width, var(--frame-height, 100%)) - var(--frame-stroke, 1px)) calc(var(--frame-height, 100%) - var(--frame-stroke, 1px)),
    var(--frame-stroke, 1px) calc(var(--frame-height, 100%) - var(--frame-stroke, 1px)),
    var(--frame-stroke, 1px) var(--frame-height, 100%),
    var(--frame-width, var(--frame-height, 100%)) var(--frame-height, 100%),
    var(--frame-width, var(--frame-height, 100%)) 0%
  );
`;