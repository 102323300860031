import Office from '../Office';
import SlideIn from './SlideIn';
import SectionTag from './SectionTag';
import style, { list as listStyle } from './ContactOffices.style';

export default function ContactOffices({ offices, active }) {
  return (
    <div css={ style }>
      <SectionTag active={ active }>
        Offices
      </SectionTag>

      <SlideIn
        css={ listStyle }
        active={ active }
        index={ 2 }
      >
        { offices.map(data => <Office key={ data.id } { ...data }/>) }
      </SlideIn>
    </div>
  );
};