import Link from './Link';
import style from './Office.style';

// https://developers.google.com/maps/documentation/urls/get-started
const createGoogleMapsUrl = (
  address1: string,
  address2: string,
  city: string,
  state: string,
  zip: string,
): string => {
  const address = [address1, address2, city, state]
    .filter(Boolean)
    .join(', ');
  const query = encodeURIComponent(`${address} ${zip}`)
    // replace spaces with "+"
    .replaceAll('%20', '+');
  return `https://www.google.com/maps/search/?api=1&query=${query}`;
};

export default function Office({
  address1,
  address2,
  city,
  state,
  zip,
  className,
}: {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  className?: string;
}) {
  return (
    <p css={ style } className={ className }>
      <Link
        external
        href={ createGoogleMapsUrl(address1, address2, city, state, zip) }
      >
        { address1 }<br />
        { address2 && <>{ address2 }<br /></> }
        { city }, { state } { zip }
      </Link>
    </p>
  );
};