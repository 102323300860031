import Typewriter from '../Typewriter';
import style from './SectionTag.style';

export default function SectionTag({ children, active }) {  
  return (
    <Typewriter
      css={ style }
      active={ active }
    >
      { children }
    </Typewriter>
  );
};