import NextLink from 'next/link';

export default function Link({
  href = '',
  external = false,
  ...rest
}) {
  if (external) {
    return (
      <a
        href={ href }
        target="_blank"
        rel="noopener noreferrer"
        { ...rest }
      />
    );
  }

  return (
    <NextLink href={ href }>
      <a { ...rest } />
    </NextLink>
  );
};