import assets from './assets.json';
import {
  isDev,
  isLowPower,
  ARTICA_S3_BUCKET,
} from '@artica/constants';

const origin = isDev ? '/' : ARTICA_S3_BUCKET;

const formatUrl = (url, origin) => {
  return origin === '/' ? url : `${origin}${url}`;
};

const pickAsset = (entry, stack) => {
  for (const key of stack) {
    if (entry[key]) return entry[key];
  }
  throw new Error(`no asset found: ${entry.name}`, entry, stack);
};

const stacks = {
  low: ['low', 'med', 'high'],
  med: ['med', 'high'],
  high: ['high'],
};

const stack = isLowPower
  ? stacks.low
  : stacks.med;

const formatAssets = obj => {
  return Object.fromEntries(
    obj.map(config => ([
      config.name,
      formatUrl(
        pickAsset(config, stack),
        origin,
      ),
    ]))
  );
};

const assetKeysToUrls = Object.fromEntries(
  Object.entries(assets.manifest)
    .map(([key, list]) => {
      return [key, formatAssets(list)];
    })
);

const all = Object.assign({}, ...Object.values(assetKeysToUrls));

export default {
  ...assetKeysToUrls,
  all,
};

export const getMimes = () => {
  return new Map(assets.mimes);
};

export const getTypes = () => {
  return new Map(assets.types);
};

export const getTarballs = () => {
  return Object.fromEntries(
    Object.entries(assets.tarballs)
      .map(([key, config]) => ([
        key,
        formatUrl(
          pickAsset(config, stack),
          origin,
        ),
      ]))
  );
};