import Link from '../Link';
import style from './Logo.style';

const IconLogo = function({ active = false }) {
  return (
    <svg
      viewBox="0 0 129 24"
      data-active={ active }
    >
      <g clipPath="url(#logo_clip_path)">
        <path d="M25.9312 0H7.71635L16.9334 15.8681H4.7343L0 24H12.2034L16.9334 15.8681L21.657 24H39.8653L25.9312 0Z" fill="white"/>
        <path d="M48.9834 6.9002L43.6106 16.1547H46.6636L47.7149 14.3435H55.0615L56.1128 16.1547H59.1658L53.793 6.9002H48.9834ZM48.8587 12.372L51.3871 8.01638L53.9155 12.372H48.8587ZM123.627 6.9002H118.818L113.445 16.1547H116.498L117.549 14.3435H124.896L125.947 16.1547H129L123.627 6.9002ZM118.693 12.372L121.221 8.01638L123.75 12.372H118.693ZM76.2261 9.00854H82.1085V16.1354H84.7401V9.01068H90.5967V6.93227H76.2261V9.01068V9.00854ZM93.0176 16.1354H95.6492V6.93227H93.0176V16.1354ZM99.904 8.21952C99.0978 9.02137 98.6871 10.1397 98.6871 11.5338C98.6871 12.928 99.0978 14.0442 99.904 14.8482C100.79 15.7163 102.587 16.4797 105.894 16.4797C108.67 16.4797 110.641 16.0584 111.776 15.5452V13.3364C110.428 13.9544 108.3 14.4269 105.866 14.4269C103.843 14.4269 102.626 14.0057 102.084 13.4668C101.648 13.0328 101.37 12.4939 101.37 11.5338C101.37 10.5738 101.635 10.0477 102.084 9.60084C102.626 9.062 103.843 8.64076 105.866 8.64076C108.298 8.64076 110.428 9.16677 111.776 9.82322V7.52244C110.639 7.02208 108.67 6.58801 105.894 6.58801C102.589 6.58801 100.79 7.35138 99.904 8.21738V8.21952ZM74.4588 10.0862C74.4588 9.31004 74.2997 8.50818 73.7192 7.93085C73.0441 7.25943 72.1067 6.93227 69.316 6.93227H61.5824V16.1354H64.2141V13.283H68.8817L70.907 16.144H73.9062L71.767 13.1226C72.7323 12.9558 73.2741 12.6607 73.6805 12.2566C74.3147 11.6258 74.4609 10.8368 74.4609 10.0862H74.4588ZM71.5498 10.9137C71.2058 11.2559 70.492 11.3478 69.2751 11.3478H64.2119V9.04703H69.2751C70.6232 9.04703 71.2445 9.10049 71.5348 9.38915C71.7326 9.58587 71.8121 9.81039 71.8121 10.1653C71.8121 10.4818 71.7197 10.7448 71.5477 10.9137H71.5498Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="logo_clip_path">
          <rect
            height="24"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default function Logo({
  active = false,
  isHome = false,
  ...rest
}) {
  // running into a problem in Next.js that mimics this comment
  // (referencing vite). because of the way next.js handles splitting
  // of files/chunks, we're getting mixed references that breaks
  // the home page. this happens when client-side navigating between
  // any page to home
  // https://github.com/pmndrs/react-three-fiber/discussions/1347#discussioncomment-2791465
  const Container = isHome ? Link : 'a';

  return (
    <Container href="/" css={ style } { ...rest }>
      <IconLogo active={ active } />
    </Container>
  );
};