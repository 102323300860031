import { css } from '@emotion/react';

export default css`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;

  a {
    flex-grow: 0;
    color: var(--color-artica-snow);
  }
`;