import { css } from '@emotion/react';

export default css`
  html, body {
    background-color: var(--page-bg-color, var(--color-artica-midnight));
  }
  
  body {
    color: var(--color-artica-snow);
    line-height: 1.5;
    font-weight: normal;
    font-size: var(--font-size-base);
    font-family: var(--font-family);

    &.artica-scroll-lock {
      overflow: hidden;
    }
  }

  button, a[href] {
    cursor: pointer;
  }
`;