import { VideoTexture } from 'three';

// cause Safari is very picky
const set = (video, attribute, value) => {
  video[attribute] = value;
  video.setAttribute(attribute, value);
};

const unsuspend = 'loadeddata';
const loop = true;
const muted = true;
const autoplay = true;
const autobuffer = true;
const playsinline = true;
const disablePictureInPicture = true;
const crossOrigin = 'anonymous';

export default class VideoLoader {
  load(url, onLoad, onProgress, onError) {
    const video = document.createElement('video');

    video.preload = 'auto';

    set(video, 'loop', loop);
    set(video, 'muted', muted);
    // autoplay now handled via useAsset
    // however, we still need to set it to true
    // so that safari will fire the loadeddata event
    set(video, 'autoplay', autoplay);
    set(video, 'autobuffer', autobuffer);
    set(video, 'playsinline', playsinline);
    set(video, 'crossOrigin', crossOrigin);
    set(video, 'disablePictureInPicture', disablePictureInPicture);

    video.src = url;
    video.onerror = onError;

    const texture = new VideoTexture(video);
    texture.autoplay = autoplay;

    video.addEventListener(unsuspend, () => {
      try { video.pause(); } catch {}
      onLoad(texture);
    });
  }
};