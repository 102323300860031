import reset from './reset';
import { root } from './variables';
import reboot from './reboot';
import fontface from './fontface';
import typography from './typography';
import utils from './utils';

const themeGlobals = [
  reset,
  root,
  reboot,
  fontface,
  typography,
  utils,
];

export default themeGlobals;