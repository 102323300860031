export default {
  async query(name, data = {}) {
    const res = await fetch(`/api/${name}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    const result = await res.json();
    
    if (res.status !== 200) {
      console.error(result.error, result.stack);
      throw new Error(`api error: ${result.error}`);
    }

    return result;
  },
};