import { useEffect, useRef } from 'react';

export default function useOutsideClick(fn) {
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = event => {
      if (!ref.current || ref.current.contains(event.target)) return;
      fn(event);
    };

    document.addEventListener('pointerdown', handleClickOutside);
    return () => (
      document.removeEventListener('pointerdown', handleClickOutside)
    );
  }, [ref, fn]);
  
  return ref;
};