import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  display: flex;
  flex-direction: column;
  justify-content: end;
  
  p {
    font-size: 0.75rem;
    
    @media ${mq.md} {
      font-size: 1rem;
    }
  }

  br {
    display: none;

    @media ${mq.md} {
      display: inline;
    }
  }
`;

export const maskInput = css`
  position: relative;

  > small {
    position: absolute;
    z-index: 1;
    inset: 1px;
    padding: 1.25rem 2.5rem 1.25rem;
    background-color: var(--color-artica-ocean-blue);
  
    opacity: 0;
    transition: opacity 300ms ease;
    
    &, * {
      pointer-events: none;
    }
  }

  &[data-disabled="true"] {
    > small {
      opacity: 1;

      &, * {
        pointer-events: auto;
      }
    }
    button {
      pointer-events: none;
      opacity: 0;
    }
  }
`;

export const button = css`
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 0.5rem;
  translate: 0 -47.5%;
  transition: opacity 300ms ease;
`;