import {
  createContext,
  useContext as useReactContext,
} from 'react';

const Context = createContext({ value: undefined });
const { Provider } = Context;

export const useContext = function(signal: any) {
  if (!signal) throw new Error(`signal is required`);

  return function SignalContext({ children }: { children: any }) {
    return (
      <Provider value={ { value: signal } }>
        { children }
      </Provider>
    );
  };
};

export default function useSignal() {
  const ctx = useReactContext(Context);
  return ctx.value;
};;