import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  margin: 0 0 3.125rem 1rem;

  small {
    font-size: 0.625rem;
  }

  @media ${mq.md} {
    margin: auto 0 0 1rem;
  }
`;