import { last } from '@immutabl3/utils';
import untar from '@immutabl3/untar';
import pako from 'pako';
import assets, {
  getMimes,
  getTypes,
  getTarballs,
} from '../assets';

const mimes = getMimes();
const types = getTypes();

// find the control key (and url) for an asset
// based on the file name in the tarball
const findManifestMatch = (assetName, manifest) => {
  for (const key in manifest) {
    const url = manifest[key];
    if (url.includes(assetName)) return { key, url };
  }
  
  console.log(`unable to find tarballed manifest for ${assetName}; falling back`);
  return null;
};

// takes a gzipped tarball and returns an array
// of file objects
const tarUrlToFiles = async function(url) {
  const res = await fetch(url);
  const buffer = await res.arrayBuffer();
  const arr = new Uint8Array(buffer);
  const result = pako.ungzip(arr);
  const entries = await untar(result.buffer);
  return entries.filter(({ type }) => type === 'file');
};

// format the files into an easily digestible object
// saturated with a blob url for quick asset loading
// outside of the worker
const format = (files, assetManifest) => {
  return Object.fromEntries(
    files
      .map(entry => {
        const { name, ext } = entry;
        const match = findManifestMatch(name, assetManifest);
        if (!match) return null;

        const { key, url } = match;
        const mime = mimes.get(ext);
        const type = types.get(ext) ?? '';
        
        return [key, {
          url,
          ext,
          type,
          blob: URL.createObjectURL(entry.getBlob(mime)),
        }];
      })
      .filter(Boolean)
  );
};

// tarball is allowed to be "incomplete"...fill in the blanks
// e.g. ios safari has a major issue with playback in video blobs
// so they are omitted from the tarball to avoid loading mp4s twice
const mergeManifest = (assets, manifest) => {
  for (const key in manifest) {
    if (assets[key]) continue;

    const url = manifest[key];
    const ext = last(url.split('.'));
    assets[key] = {
      url,
      ext,
      type: types.get(ext) ?? '',
      blob: '',
    };
  }
  return assets;
};

export const preloadAssets = async function() {
  const files = await tarUrlToFiles(getTarballs().preload);
  const results = format(files, assets.preload);
  return mergeManifest(results, assets.preload);
};

export const loadAssets = async function() {
  const files = await tarUrlToFiles(getTarballs().main);
  const results = format(files, assets.main);
  return mergeManifest(results, assets.main);
};