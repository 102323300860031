import { once, identity } from '@immutabl3/utils';

const listeners = new Set();
const library = new Map();
const preprocessors = new Map();

export default {
  load(key, rawAsset) {
    library.set(key, rawAsset);

    for (const listener of listeners.values()) {
      listener(key, rawAsset);
    }
  },

  preprocess(key, fn) {
    if (preprocessors.has(key)) throw new Error(`preprocessor for ${key} already exists`, fn);
    preprocessors.set(key, once(fn));
  },

  has(keys) {
    for (const key of keys) {
      if (!library.has(key)) return false;
    }
    return true;
  },

  fulfill(keys) {
    return Object.fromEntries(
      keys.map(key => {
        const preprocessor = preprocessors.has(key)
          ? preprocessors.get(key)
          : identity;
        return [key, preprocessor(library.get(key))];
      })
    );
  },

  onChange(fn) {
    listeners.add(fn);
    return () => listeners.delete(fn);
  },
};