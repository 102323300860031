import style from './Subnav.style';
import SlideIn from './SlideIn';
import Link from '../Link';

export default function Subnav({ links, active }) {
  return (
    <SlideIn
      as="nav"
      css={ style }
      active={ active }
      index={ 3 }
      aria-label="subnav"
    >
      { links.map(({ id, url, name }) => (
        <Link key={ id } href={ url } className="small">
          { name }
        </Link>
      )) }
    </SlideIn>
  );
};