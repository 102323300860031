import { css } from '@emotion/react';

export default css`
  color: var(--color-artica-cloud);
  font-size: 0.75rem;

  > span {
    display: inline-block;
    opacity: 0;
  }
`;