import cookies from 'cookies-js';
import client from './utils/client';
import localStore from './utils/localStore';
import { SESSION_ID } from '@artica/constants';
import { preload as assetPreload } from '@artica/assets';
import { isSafari } from '@immutabl3/browser-environment';
import {
  loadAssets,
  preloadAssets,
} from './worker/loadAssets';

const cacheAssets = async function(assets) {
  return Promise.all(
    Object.entries(assets)
      .map(async function([key, asset]) {
        const { blob, url, type } = asset;
        const loader = assetPreload[type];
        if (!loader) throw new Error(`unknown asset type: ${asset?.type}`, asset);
        // safari has a major regression with blob playback
        // the loader/preloader already requested the asset
        // so it should be cached and relatively safe to pass
        // the url here
        // https://discourse.threejs.org/t/safari-webgl-issues-with-video-playback-and-requestanimationframe-performance/15452/4
        const value = type === 'video' && isSafari() ? url : blob;
        return loader(key, value);
      })
  );
};

export const loadInsights = async function(store) {
  const cachedInsights = localStore.get('insights');
  const insights = cachedInsights 
    ? cachedInsights
    : await client.query('insights');
  store.set(['insights'], insights);
};

export const preload = async function() {
  return cacheAssets(await preloadAssets());
};

export const load = async function() {
  return cacheAssets(await loadAssets());
};

export const loadUser = async function() {
  const userId = cookies.get(SESSION_ID);
  return localStore.get(userId) || { id: userId };
};
