import { css } from '@emotion/react';

export default css`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 3rem;
  padding-left: 1rem;

  --link-height: 2.75rem;
  --indicator-width: 2px;
  --indicator-height: 1.3125rem;
`;