import { useRef, useEffect } from 'react';
import { anime } from '@immutabl3/anime';
import style from './SlideIn.style';

const useSlideIn = (active, index) => {
  const ref = useRef();

  useEffect(() => {
    if (!active) return;

    const anim = anime({
      targets: ref.current,
      easing: 'easeOutSine',
      translateY: [20, 0],
      opacity: [0, 1],
      duration: 500,
      delay: ((index + 1) * 75) + 100,
    });

    return () => {
      anim.restart();
      anim.pause();
    };
  }, [active, index]);

  return ref;
};

export default function SlideIn({
  as: Tag = 'div',
  active = false,
  index = 0,
  ...props
}) {
  return (
    <Tag
      ref={ useSlideIn(active, index) }
      css={ style }
      { ...props }
    />
  );
};