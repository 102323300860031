import { css } from '@emotion/react';

export default css`
  display: block;
  height: 50px;
  padding: 13px 0;
  margin-top: -13px;

  svg {
    width: 129px;
    height: 24px;
    translate: 43px 0;
    transition: translate 500ms ease-in-out;
    will-change: translate;

    rect {
      width: 43px;
      transition: width 500ms ease-in-out;
      will-change: width;
    }

    &[data-active="true"] {
      translate: 0 0;

      rect {
        width: 129px;
      }
    }
  }
`;