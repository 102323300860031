import { isSafari, isMobile } from '@immutabl3/browser-environment';

export const isDev = process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV === 'production';

export const isClient = globalThis.window !== undefined;
export const isServer = !isClient;

export const SITE_NAME = process.env.SITE_NAME ?? 'Artica';

export const DOMAIN_URL = process.env.DOMAIN_URL ?? 'https://www.artica.com';

export const ARTICA_API_URL = process.env.ARTICA_API_URL ?? '';
export const ARTICA_API_KEY = process.env.ARTICA_API_KEY ?? '';
export const ARTICA_CONTACT_URL = process.env.ARTICA_CONTACT_URL ?? '';

export const STRAPI_API_URL = process.env.STRAPI_API_URL ?? 'http://127.0.0.1:1337';
export const STRAPI_API_TOKEN = process.env.STRAPI_API_TOKEN ?? '';
export const STRAPI_REVALIDATE_TOKEN = process.env.STRAPI_REVALIDATE_TOKEN ?? '';

export const NEXT_REVALIDATE = 60 * 60; // 1 hour (in s)

export const USER_CACHE_SIZE = 1000;
export const USER_CACHE_TTL = 24 * 60 * 1000; // 24 hours (in ms)

export const INSIGHTS_CACHE_SIZE = 1000;
export const INSIGHTS_CACHE_TTL = 24 * 60 * 1000; // 24 hours (in ms)

export const ARTICA_STORE_VERSION = process.env.NEXT_PUBLIC_ARTICA_STORE_VERSION || 'v0';
export const ARTICA_S3_BUCKET = process.env.NEXT_PUBLIC_ARTICA_S3_BUCKET ?? '/';

export const SESSION_ID = 'x-artica-user-id';
export const ACCEPTANCE_ID = 'x-artica-terms-accepted';
export const TRACKING_ID = 'x-artica-tracking-id';
export const TRACKING_QUERY_KEY = 'pid';

export const CURVE_PATH_LIMIT = 0.98;

export const isLowPower = isClient
  ? isMobile() && isSafari()
  : false;

export const KERNEL_SIZE = isClient ? (
  isLowPower
    ? 'VERY_SMALL'
    : 'SMALL'
) : '';

export const UTM_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];