import Link from '../Link';
import style from './NavItem.style';

export default function NavItem({ url, name, active }) {
  // see icon/Logo.jsx for more info on this
  const Container = url === '/' ? 'a' : Link;
  return (
    <Container
      href={ url }
      css={ style }
      data-active={ active }
    >
      { name }
    </Container>
  );
};