import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: var(--z-main-header);
  inset: 1.5rem 1rem auto 1rem;
  height: 1.5rem;
  translate: 0 0 1px;

  @media ${mq.md} {
    top: 2.5rem;
  }

  &[data-affix="true"] {
    position: fixed;
  }
`;