import style from './Triangle.style';

const SIZE = 100;
const WIDTH = 18 * 2;
const HEIGHT = 15 * 2;
const BLUR = 8 * 2;

const draw = (canvas, color) => {
  if (!canvas) return;

  const cw = canvas.width = SIZE;
  const ch = canvas.height = SIZE;

  const w = WIDTH;
  const h = HEIGHT;

  const ctx = canvas.getContext('2d');

  const x = (cw - w) / 2;
  const y = (ch - h) / 2;

  ctx.fillStyle = color;
  ctx.shadowColor = color;
  ctx.shadowBlur = BLUR;

  ctx.moveTo(x, y);
  ctx.lineTo(x + w, y);
  ctx.lineTo(x + (w / 2), y + h);

  ctx.fill();
};

export default function IconTriangle({
  color = '#fff',
  up = false,
  down = true,
  left = false,
  right = false,
  ...props
}) {
  return (
    <canvas
      ref={ elem => draw(elem, color) }
      css={ style }
      { ...props }
      style={ {
        rotate: `${up ? 180 : left ? 90 : right ? -90 : down ? 0 : 0}deg`,
      } }
    />
  );
};