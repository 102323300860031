import Nav from './Nav';
import Scroll from './Scroll';
import Footer from './Footer';
import Hamburger from './Hamburger';
import SiteLogo from './SiteLogo';
import SlideIn from './SlideIn';
import ContactForm from '../ContactForm';
import ContactPhone from './ContactPhone';
import ContactOffices from './ContactOffices';
import ContactSocial from './ContactSocial';
import Subnav from './Subnav';
import Mask from './Mask';
import style from './Launchpad.style';
import { useLaunchpad } from './Launchpad.hooks';

export default function Launchpad({
  social,
  nav: {
    links,
    sublinks,
  },
  meta: {
    offices,
    phone,
  },
}) {
  const { ref, active, onToggle } = useLaunchpad();
  
  return (
    <>
      <Hamburger
        active={ active }
        onPointerDown={ onToggle }
      />
      <div
        ref={ ref }
        css={ style }
        data-active={ active }
      >
        <Scroll>
          <Nav
            links={ links }
            active={ active }
          />
          <SlideIn
            active={ active }
            index={ 0 }
          >
            <ContactForm />
          </SlideIn>
          <ContactPhone
            phone={ phone }
            active={ active }
          />
          <ContactOffices
            offices={ offices }
            active={ active }
          />
          <ContactSocial
            social={ social }
            active={ active }
          />
          <Footer active={ active }>
            <Subnav
              links={ sublinks }
              active={ active }
            />
          </Footer>
        </Scroll>
      </div>
      <Mask active={ active } />
      <SiteLogo active={ active } />
    </>
  );
};