import { useRouter } from 'next/router';
import style from './Hamburger.style';

export default function Hamburger({
  active,
  onPointerDown,
}) {
  const { pathname } = useRouter();

  return (
    <button
      css={ style }
      data-overlaid={ pathname !== '/' }
      className="hide-text"
      type="button"
      role="button"
      aria-expanded={ active }
      title="Show Navigation"
      tabIndex={ 0 }
      onPointerDown={ onPointerDown }
    >
      <span>
        Menu
      </span>
    </button>
  );
};