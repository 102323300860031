import Link from '../Link';
import Facebook from './Facebook';
import LinkedIn from './LinkedIn';
import Twitter from './Twitter';
import Vimeo from './Vimeo';
import YouTube from './YouTube';

const toUrl = (handle = '', link = '') => {
  if (!handle) return link;
  if (link.includes(handle)) return link;

  const joint = link.endsWith('/') ? '' : '/';
  return `${link}${joint}${handle}`;
};

const socials = new Map([
  ['facebook', Facebook],
  ['linkedin', LinkedIn],
  ['twitter', Twitter],
  ['vimeo', Vimeo],
  ['youtube', YouTube],
]);

export default function IconSocial({
  link,
  type,
  handle = '',
  ...rest
}) {
  if (!socials.has(type)) return null;

  const Icon = socials.get(type);

  return (
    <Link
      external
      href={ toUrl(handle, link) }
      aria-label={ type }
      { ...rest }
    >
      <Icon />
    </Link>
  );
}