import style, {
  button as buttonStyle,
  maskInput as maskInputStyle,
} from './ContactForm.style';
import { useCallback, useState } from 'react';

import Input from './form/Input';
import Triangle from './icons/Triangle';
import Typewriter from './Typewriter';
import { useSignal } from '@artica/hooks';
import { useStore } from '@immutabl3/store/react';

export default function ContactForm({
  children,
  disabled: isDisabled = false,
  ...props
}) {
  const signal = useSignal();
  const hasEmail = !!useStore(['user', 'email']).length;
  const disabled = hasEmail || isDisabled;
  const [value, setValue] = useState('');
  const onChange = useCallback(e => {
    setValue(e.target.value);
  }, []);

  const onSubmit = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    if (hasEmail) return;

    const clearbit = window.clearbit || { identify: () => { } };

    const data = Object.values(e.target)
      .reduce((obj, field) => {
        if (!field.name) return obj;
        obj[field.name] = field.value;
        return obj;
      }, {});

    clearbit.identify(data.email, {
      email: data.email,
    });
    signal.emit('user:lead', data);
  }, [signal, hasEmail]);

  return (
    <form id='artica-sign-up' css={style} {...props} onSubmit={onSubmit}>
      {children || (
        <p>
          <strong>Become one of the first. </strong><br />
          <small>
            Sign up for Artica updates and early access <br />
            to our seller platform.
          </small>
        </p>
      )}
      <div css={maskInputStyle} data-disabled={disabled}>
        <Typewriter active={hasEmail}>
          email received.
        </Typewriter>
        <Input
          type="email"
          name="email"
          disabled={disabled}
          placeholder={disabled ? '' : 'email'}
          value={disabled ? '' : value}
          tabIndex={disabled ? -1 : 0}
          onChange={onChange}
          required
        >
          <button
            css={buttonStyle}
            type="submit"
            tabIndex={disabled ? -1 : undefined}
          >
            <Triangle right />
          </button>
        </Input>
      </div>
      {/* honeypot */}
      <div className="visually-hidden">
        <Input
          type="text"
          name="name"
          tabIndex={-1}
        />
      </div>
    </form>
  );
};