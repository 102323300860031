import { css } from '@emotion/react';

export default css`
  a {
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
`;