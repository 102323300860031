import { css } from '@emotion/react';

export default css`
  position: absolute;
  width: var(--indicator-width);
  height: var(--indicator-height);
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-color: var(--color-artica-neo-green);
  transition: translate 300ms ease-in;

  --offset: calc((var(--link-height) - var(--indicator-height)) / -2);

  &[data-active="3"] {
    translate: 0 calc((var(--link-height) * -2) + var(--offset));
  }
  &[data-active="2"] {
    translate: 0 calc((var(--link-height) * -1) + var(--offset));
  }
  &[data-active="1"] {
    translate: 0 calc((var(--link-height) * 0) + var(--offset));
  }
  &[data-active="0"] {
    visibility: hidden;
  }
`;