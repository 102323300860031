import { css } from '@emotion/react';
import { mq } from './variables';

export default css`
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-bottom: 1.5rem;
    font-weight: bold;
    font-family: var(--font-family-display);
  }

  h1, .h1 {
    font-size: var(--font-size-h1);
  }
  h2, .h2 {
    line-height: 1.25;
    font-size: var(--font-size-h2);
  }
  h3, .h3 {
    font-size: var(--font-size-h3);
  }
  h4, .h4 {
    font-size: var(--font-size-h4);
  }
  h5, .h5 {
    font-size: var(--font-size-h5);
  }
  h6, .h6 {
    font-size: var(--font-size-h6);
  }

  p {
    margin-bottom: 1.5em;
    font-size: var(--font-size-base);
    font-weight: 300;
  }

  // low specificity
  :where(a[href]) {
    color: var(--color-artica-snow);
  }

  strong, .strong {
    font-weight: bold;
  }

  .center { text-align: center; }
  
  .text-display {
    @media ${mq.sm} {
      text-align: center;
    }
  }

  small, .small {
    font-size: 0.75em;
  }
`;