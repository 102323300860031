import { css } from '@emotion/react';

export default css`
  .hide-text {
    overflow: hidden;
    color: transparent;
    text-indent: -9999rem;
  }
  
  .visually-hidden {
    position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height: 1px; width: 1px; 
    margin: -1px;
    padding: 0;
    border: 0; 
  }

  .interact {
    &, * {
      pointer-events: auto;
    }
  }
  .no-interact {
    &, * {
      pointer-events: none;
    }
  }
`;