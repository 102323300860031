import { css } from '@emotion/react';

export default css`
  *, *:before, *:after {
    box-sizing: border-box;
    
    border: 0;
    outline: 0;

    margin: 0;
    padding: 0;
  }

  button {
    border: 0;
    background-color: transparent;
  }

  ol, ul {
    list-style: none;
  }

  html, body {
    font-size: 16px;
  }

  img {
    height: auto;
    max-width: 100%;
  }
`;