import { isFunction, identity, noop } from '@immutabl3/utils';
import { LUT3dlLoader } from 'postprocessing';
import { TextureLoader } from 'three';
import VideoLoader from './VideoLoader';
import library from './library';

import {
  FBXLoader,
  RGBELoader,
  GLTFLoader,
  DRACOLoader,
  MeshoptDecoder,
} from 'three-stdlib';

const buildGraph = object => {
  const data = {
    nodes: {},
    materials: {},
  };

  if (!object) return data;
  
  object.traverse(obj => {
    if (obj.name) data.nodes[obj.name] = obj;
    if (obj.material && !data.materials[obj.material.name]) data.materials[obj.material.name] = obj.material;
  });
  
  return data;
};

const dracoLoader = new DRACOLoader();

const gltfExtension = loader => {
  // https://www.gstatic.com/draco/versioned/decoders/1.4.3/draco_wasm_wrapper.js
  dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.3/');
  loader.setDRACOLoader(dracoLoader);
  loader.setMeshoptDecoder(isFunction(MeshoptDecoder) ? MeshoptDecoder() : MeshoptDecoder);
  return loader;
};

const load = (Proto, url, extensions = identity) => {
  const loader = extensions(new Proto());
  return new Promise((resolve, reject) => {
    loader.load(
      url,
      data => {
        if (data.scene) Object.assign(data, buildGraph(data.scene));
        resolve(data);
      },
      noop, 
      error => reject(error)
    );
  });
};

const loader = (type, Loader, extensions) => {
  return async function loader(key, url) {
    try {
      const result = await load(Loader, url, extensions);
      library.load(key, result);
    } catch (e) {
      console.error(`missing asset: ${type}: ${key}`, e);
    }
  };
};

export default {
  gltf: loader('gltf', GLTFLoader, gltfExtension),
  texture: loader('texture', TextureLoader),
  image: loader('image', RGBELoader),
  lut: loader('lut', LUT3dlLoader),
  fbx: loader('fbx', FBXLoader),
  video: loader('video', VideoLoader),
};