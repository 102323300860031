import { useCallback } from 'react';
import Logo from '../icons/Logo';
import { noop } from '@immutabl3/utils';
import { alchemy, useLab } from '@immutabl3/alchemy';
import { useRouter } from 'next/router';
import style from './SiteLogo.style';

export default function SiteLogo({ active }) {
  const scrollTop = useLab('y');
  const { pathname } = useRouter();
  const isHome = pathname === '/';
  const toTop = useCallback(e => {
    e.preventDefault();
    alchemy.jumpTo({ top: 0 });
  }, []);
  const isActive = isHome
    ? scrollTop < 1 || active
    : active;

  return (
    <header
      css={ style }
      data-affix={ isHome }
    >
      <Logo
        active={ isActive }
        isHome={ isHome }
        onClick={ isHome ? toTop : noop }
      />
    </header>
  );
};