import Social from '../icons/Social';
import style from './ContactSocial.style';
import { useStaggerIn } from './Launchpad.hooks';

export default function ContactSocial({ social, active }) {
  return (
    <nav
      ref={ useStaggerIn(active) }
      css={ style }
      aria-label="social"
    >
      { Object.entries(social)
        .map(([key, { link, handle, type }]) => (
          <Social
            key={ key }
            type={ type } 
            link={ link }
            handle={ handle }
          />
        )) }
    </nav>
  );
};