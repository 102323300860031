import style from './Nav.style';
import NavItem from './NavItem';
import NavIndicator from './NavIndicator';
import SectionTag from './SectionTag';
import { useNav, useStaggerIn } from './Launchpad.hooks';

export default function Nav({ links, active }) {
  const {
    activeIndex,
    indicatorOffset,
  } = useNav(links);
  
  return (
    <nav
      ref={ useStaggerIn(active) }
      css={ style }
      aria-label="main"
    >
      <SectionTag active={ active }>
        Navigation
      </SectionTag>
      
      { links.map((props, idx) => (
        <NavItem
          { ...props }
          key={ props.id }
          active={ activeIndex === idx } />
      )) }

      <NavIndicator offset={ indicatorOffset } />
    </nav>
  );
};