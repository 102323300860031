import style from './Footer.style';
import SlideIn from './SlideIn';

const year = new Date().getFullYear();

export default function Footer({ children, active }) {
  return (
    <footer css={ style }>
      { children }
      <SlideIn
        as="small"
        index={ 4 }
        active={ active }
      >
        Copyright © { year } ARTICA - All Rights Reserved
      </SlideIn>
    </footer>
  );
};