import { useRef, useState, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { window } from '@immutabl3/alchemy';
import { anime, stagger } from '@immutabl3/anime';
import { useSignal, useOutsideClick } from '@artica/hooks';
import { useStore } from '@immutabl3/store/react';

export const useLaunchpad = () => {
  const router = useRouter();
  const signal = useSignal();
  const active = useStore(['menuActive']);

  const onToggle = useCallback(e => {
    if (e.isPropagationStopped()) return;

    signal.emit('menu:toggle', !active);
  }, [signal, active]);

  const onClose = useCallback(e => {
    if (!active) return;

    e.stopPropagation();
    signal.emit('menu:toggle', false);
  }, [signal, active]);
  
  const containerRef = useOutsideClick(onClose);

  useEffect(() => {
    if (active === false) globalThis.document.body.classList.remove('artica-scroll-lock');
    if (active === false) window.resume();

    if (active === true) globalThis.document.body.classList.add('artica-scroll-lock');
    if (active === true) window.pause();
  }, [active]);

  useEffect(() => {
    const onClose = () => {
      signal.emit('menu:toggle', false);
    };
    router.events.on('routeChangeStart', onClose);
    
    const onKeyDown = e => {
      if (e.key !== 'Escape') return;
      onClose();
    };
    globalThis.document.addEventListener('keydown', onKeyDown);
    
    return () => {
      router.events.off('routeChangeStart', onClose);
      globalThis.document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return {
    ref: containerRef,
    active,
    onToggle,
  };
};

export const useStaggerIn = active => {
  const ref = useRef();

  useEffect(() => {
    if (!active) return;

    const anim = anime({
      targets: ref.current.querySelectorAll('a'),
      easing: 'easeOutQuad',
      translateY: [20, 0],
      opacity: [0, 1],
      duration: 500,
      delay: stagger(150, { start: 200 }),
    });

    return () => {
      anim.restart();
      anim.pause();
    };
  }, [active]);

  return ref;
};

export const useNav = links => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [indicatorOffset, setIndicatorOffset] = useState(0);
  const { pathname } = useRouter();

  useEffect(() => {
    const activeIndex = links.findIndex(({ url }) => (url === pathname));
    setActiveIndex(activeIndex);
    
    const indicatorOffset = links.length - activeIndex;
    setIndicatorOffset(activeIndex === -1 ? 0 : indicatorOffset);
  }, [pathname]);

  return {
    activeIndex,
    indicatorOffset,
  };
};